import React,{Component} from 'react';
import SectionTitle from './SectionTitle';

class TeamMemberArea extends Component{
  state = {
    sectionTitle:{
      title:'Conheça o Time',
      subtitle: ' ',
      paragraph: 'A Puzli nasceu do sonho de melhorar o mercado de eventos e cerimoniais em constante mutação. As sócias tinham um desejo enorme em comum, que era o de criar algo que realmente fizesse diferença no mercado de eventos.',
      titleclassName: 'p-width-lg'
    },
    teamMembers: [
      {
        img: require('../../assets/img/team-member/01.jpg'),
        title: 'Amanda Holanda',
        subtitle: 'CTO - Engenheira de Software',
        socialIcon: [
          // {
          //   link: '#!',
          //   icon : 'icofont-facebook'
          // },
          // {
          //   link: '#!',
          //   icon : 'icofont-twitter'
          // },
          // {
          //   link: '#!',
          //   icon : 'icofont-instagram'
          // },
        ]
      },
      {
        img: require('../../assets/img/team-member/02.jpg'),
        title: 'Denise Rocha',
        subtitle: 'CEO - Publicitária',
        socialIcon: [
          // {
          //   link: '#!',
          //   icon : 'icofont-facebook'
          // },
          // {
          //   link: '#!',
          //   icon : 'icofont-twitter'
          // },
          // {
          //   link: '#!',
          //   icon : 'icofont-instagram'
          // },
        ]
       },
      // {
      //   img: require('../../assets/img/team-member/03.jpg'),
      //   title: 'Homer Patrick',
      //   subtitle: 'Visual Designer',
      //   socialIcon: [
      //     {
      //       link: '#!',
      //       icon : 'icofont-facebook'
      //     },
      //     {
      //       link: '#!',
      //       icon : 'icofont-twitter'
      //     },
      //     {
      //       link: '#!',
      //       icon : 'icofont-instagram'
      //     }
      //   ]
      // },
      // {
      //   img: require('../../assets/img/team-member/04.jpg'),
      //   title: 'Janie Klein',
      //   subtitle: 'Visual Designer',
      //   socialIcon: [
      //     {
      //       link: '#!',
      //       icon : 'icofont-facebook'
      //     },
      //     {
      //       link: '#!',
      //       icon : 'icofont-twitter'
      //     },
      //     {
      //       link: '#!',
      //       icon : 'icofont-instagram'
      //     },
      //   ]
      // }
    ]
  }
  render(){
    return (
      <section className="team-member-area" id="team">
          <div className="left-image">
              <div className="img-wrapper">
                  <img src={require('../../assets/img/team-left-image.png')} alt="team member left"/>
              </div>
          </div>
          <div className="right-image">
              <div className="img-wrapper">
                  <img src={require('../../assets/img/team-right-image.png')} alt="team member right"/>
              </div>
          </div>
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <SectionTitle title={this.state.sectionTitle.title} subtitle={this.state.sectionTitle.subtitle} paragraph={this.state.sectionTitle.paragraph} titleclassName={this.state.sectionTitle.titleclassName}/>
                  </div>
              </div>
              <div className="row justify-content-center">
                {
                  this.state.teamMembers.map((team,index) => (
                    <div className="col-lg-3 col-md-6" key={index}>
                        <div className="single-team-member">
                            <div className="thumb">
                                <img src={team.img} alt="team member"/>
                            </div>
                            <div className="content">
                                <h4 className="title">{team.title}</h4>
                                <span className="post">{team.subtitle}</span>
                                <ul className="social-icon">
                                {
                                  team.socialIcon.map((social,index) => (
                                      <li key={index}><a href={social.link}><i className={social.icon}></i></a></li>
                                  ))
                                }
                                </ul>
                            </div>
                        </div>
                    </div>
                  ))
                }
              </div>
          </div>
      </section>
    )
  }
}

export default TeamMemberArea;
