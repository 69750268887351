import React,{Component} from 'react';
import $ from 'jquery';
import {Link} from 'react-router-dom';

class Navbar extends Component{

componentDidMount(){
  $(document).on('click','#primary-menu li a[data-toggle="dropdown"]', function (e){
    e.preventDefault();
  });
  $(document).on('click','#primary-menu li a', function (e) {
      var anchor = $(this).attr('href');
      var link = anchor.slice(0,1);
      if ('#' === link) {
          e.preventDefault();
          $('html, body').animate({
              scrollTop: $(anchor).offset().top
          }, 1000);
          $(this).parent().addClass('active').siblings().removeClass('active');
      }
  });
}//end couponentDidMount

 state = {
   navbarClass: this.props.navClass ? this.props.navClass : false
 }

  render(){
    return (

      <nav className={`navbar navbar-area navbar-expand-lg nav-fixed navbar-light ${this.state.navbarClass}`}>
        <div className="container">
            
            <Link to="/" ><span className="navbar-brand logo">
                <img src={require('../../assets/img/logo-white.png')} className="white" alt="logo"/>
                {/* <img src={require('../../assets/img/logo.png')} className="black" alt="logo"/> */}
                </span></Link>
            <div className="collapse navbar-collapse" id="apptidy">
                <ul className="navbar-nav" id="primary-menu">
                    <li className="nav-item active">
                    <Link to="/" className="nav-link pl-0" >Home
                            <span className="sr-only">(current)</span>
                        </Link>
                    </li>
                    {/* <li className="nav-item">
                        <a className="nav-link" href="#feature">Features</a>
                    </li> */}
                    {/* <li className="nav-item">
                        <a className="nav-link" href="#screenshort">Screens</a>
                    </li> */}
                    {/* <li className="nav-item">
                        <a className="nav-link" href="#pricing">Pricing</a>
                    </li> */}
                    <li className="nav-item">
                        <a className="nav-link" href="#team">Equipe</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#contact">Contato</a>
                    </li>
                    <li className="nav-item navbar-btn-wrapper">
                        <a className="nav-link boxed-btn blank" href="#!">LOGIN</a>
                    </li>
                </ul>
            </div>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#apptidy" aria-controls="apptidy" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
        </div>
    </nav>
    )
  }
}


export default Navbar;
