import React,{Component} from "react";
import Regulamento from './components/Regulamento'

class Lgpd extends Component{
    componentDidMount(){
        document.scrollingElement.scrollTop = 0;
      }
    
    render(){
  return (
    <section className="team-member-area" id="team" style={{position: 'inherit'}}>
          <div className="left-image">
              <div className="img-wrapper">
                  <img src={require('../assets/img/team-left-image.png')} alt="team member left"/>
              </div>
          </div>
          <div className="right-image">
              <div className="img-wrapper">
                  <img src={require('../assets/img/team-right-image.png')} alt="team member right"/>
              </div>
          </div>
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-lg-10" style={{backgroundColor: 'rgba(0,0,0,.1)', padding: '20px', borderRadius: '10px'}}>
                    <Regulamento/>
                  </div>
              </div>
              </div>
</section>

  )
}
}

export default Lgpd