import React from "react";

const Regulamento = () => {
  return (
    <>
      <div>
        <div className="WordSection1">
          <h2>
            <b>
              <span
                style={{
                  fontSize: "14.0pt",
                  color: "#212529",
                }}
              >
                Regulamento PUZLI – QR EXPERIENCE
              </span>
            </b>
          </h2>

          <p className="MsoNormal">
            <b>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                1.    DEFINIÇÕES
              </span>
            </b>
          </p>

          <p className="MsoNormal">
            <span>
              1.1.    Nas cláusulas e condições que compõem este Regulamento,
              serão utilizadas as definições a seguir:
            </span>
          </p>

          <p className="MsoNormal">
            <span>    a)</span>

            <span
              style={{
                color: "#212529",
              }}
            >
                  <b>Puzli</b>: É um sistema que visa exclusivamente fazer a
              gestão de convidados em eventos por meio de QR Code.
            </span>
          </p>

          <p className="MsoNormal">
            <span>    b)    Site Puzli</span>
            <span>: Domínio</span>
            <span>
              <a href="http://www.dotz.com.br/">
                <span>www.puzli.com.br</span>
              </a>
            </span>
            <span>
              {" "}
              em que estarão hospedadas as principais informações relativas à
              Puzli.
            </span>
          </p>

          <p className="MsoNormal">
            <span>
                   c)    <b>Parceiros</b>: Pessoas físicas ou jurídicas que
              oferecem o sistema <b>Puzli </b>aos seus clientes em decorrência
              de utilização dos serviços.{" "}
            </span>
          </p>

          <p className="MsoNormal">
            <span>
                  d)    <b>Anfitrião</b>
            </span>
            <span>
              : Contratantes e Participantes que possuem a responsabilidade
              sobre uma <b>Conta Puzli.</b>
            </span>
          </p>

          <p className="MsoNormal">
            <span>
                  e)    <b>Convidado ou Participante</b>
            </span>
            <span>
              : Pessoa física que acessa e/ou utiliza as funcionalidades da{" "}
              <b>PUZLI</b>.
            </span>
          </p>

          <p className="MsoNormal">
            <b>
              <span>   f)    Política de Privacidade Puzli</span>
            </b>
            <span>
              : Documento que dispõe a respeito da privacidade e da proteção dos
              dados pessoais coletados dos usuários do sistema Puzli.
            </span>
          </p>

          <p className="MsoNormal" style={{ marginTop: "35px" }}>
            <b>
              <span style={{ fontSize: "14pt", color: "black" }}>
                2.    CADASTRO DO CONVIDADO
              </span>
            </b>
          </p>

          <p className="MsoNormal">
            <span>
              2.1.    O cadastro é realizado diretamente no link do evento
              específico no sitema <b>Puzli</b>.
            </span>
          </p>

          <p className="MsoNormal">
            <span></span>
          </p>

          <p className="MsoNormal">
            <span>
              2.2.    Para a realização do cadastro, o Participante deverá
              informar nome, telefone, email, whatsapp e uma foto de seu rosto.{" "}
            </span>
          </p>

          <p className="MsoNormal">
            <span></span>
          </p>

          <p className="MsoNormal">
            <span>
              2.3.    Os dados coletados serão usados para identificar o usuário
              no sistema<b> Puzli </b>e assim cumprir obrigações, a exemplo de
              enviar o QR Code individual no número cadastrado e fazer o
              reconhecimento facial na portaria do evento.
            </span>
          </p>

          <p className="MsoNormal">
            <span></span>
          </p>

          <p className="MsoNormal">
            <span>
              2.4.    As informações de cadastro poderão ser enriquecidas ou
              compartilhadas com parceiros e anfitriões, mediante informação e
              consentimento prévio do usuário e nos termos da Política de
              Privacidade <b>Puzli </b>e da Lei de Proteção de Dados Pessoais
              aplicável.{" "}
            </span>
          </p>

          <p className="MsoNormal">
            <span></span>
          </p>

          <p className="MsoNormal">
            <span>
              2.5.    A alteração, adição, retirada ou exclusão de qualquer dos
              dados pessoais poderá ser realizada pelo próprio anfitrião no{" "}
              <b>sistema Puzli</b>, mediante login e senha de acesso.{" "}
            </span>
          </p>

          <p className="MsoNormal">
            <span></span>
          </p>

          <p className="MsoNormal">
            <span>
              2.6.    Após o cadastro no <b>sistema Puzli</b>, o Convidado
              receberá em seu whatsapp seu QR Code personalizado com as
              informações cadastradas e deverá apresentá-lo na portaria do
              evento.
            </span>
          </p>

          <p className="MsoNormal" style={{ marginTop: "35px" }}>
            <b>
              <span
                style={{
                  fontSize: "14pt",
                }}
              >
                3.    COMUNICAÇÃO COM O SISTEMA PUZLI
              </span>
            </b>
            <span></span>
          </p>

          <p className="MsoNormal">
            <span>
              3.1.    O Participante poderá receber comunicações relativas aos
              produtos e benefícios oferecidos pelo <b>sistema Puzli</b>, tais
              como oferta de produtos e serviços, campanhas promocionais, e-mail
              marketing, entre outros. O contato com o Participante cadastrado
              no sistema <b>Puzli </b>poderá ocorrer por todos os meios de
              comunicação disponíveis, incluindo ligação telefônica, e-mail,
              short message service service (sms), push notification App (Push)
              e WhatsApp.{" "}
            </span>
          </p>

          <p className="MsoNormal">
            <span>
              3.2.    Fica reservado ao Participante o direito de, a qualquer
              tempo, conceder, alterar ou revogar a autorização para envio de
              comunicações pelos diversos canais informativos utilizados,
              exceção feita às comunicações institucionais e/ou transacionais,
              necessárias à operação e regular funcionamento do{" "}
              <b>sistema Puzli</b>, e fundamentais para a ciência dos Convidados
              nos termos acima.
            </span>
          </p>

          <p className="MsoNormal" style={{ marginTop: "35px" }}>
            <b>
              <span style={{ fontSize: "14pt", color: "#212529" }}>
                4.    POLÍTICA DE PRIVACIDADE{" "}
              </span>
            </b>
          </p>

          <p className="MsoNormal" style={{ textAlign: "justify" }}>
            <span>
              A presente<b> Política de Privacidade</b> tem por finalidade
              demonstrar o compromisso da{" "}
            </span>
            <b>
              <span>Puzli, </span>
            </b>
            <span>
              pessoa jurídica de direito privado, inscrita no CNPJ sob o nº
              48.338.913/0001-49,{" "}
            </span>
            <span>
              com a privacidade e a proteção dos dados pessoais coletados de
              seus <b>USUÁRIOS</b>, estabelecendo as regras sobre a coleta,
              registro, armazenamento, uso, compartilhamento, enriquecimento e
              eliminação dos dados coletados dos <b>USUÁRIOS</b>, dentro do
              escopo dos serviços e funcionalidades da <b>PUZLI</b>, de acordo
              com as leis em vigor, com transparência e clareza junto ao{" "}
              <b>USUÁRIO </b>e ao mercado em geral.
            </span>
          </p>

          <p className="MsoNormal" style={{ textAlign: "justify" }}>
            <span>
              Como condição para acesso e uso das funcionalidades exclusivas da{" "}
              <b>PUZLI</b>,{" "}
              <u>
                o <b>USUÁRIO </b>declara que fez a leitura completa e atenta da
                presente <b>Política de Privacidade</b>, estando plenamente
                ciente, conferindo, assim, sua livre e expressa concordância com
                os termos aqui estipulados, autorizando a obtenção dos dados e
                informações aqui mencionados, bem como sua utilização para os
                fins abaixo especificados
              </u>
              . Caso não esteja de acordo com estas Diretivas, o <b>USUÁRIO </b>
              deverá descontinuar o seu acesso.
            </span>
          </p>
          <p className="MsoNormal" style={{ marginTop: "25px" }}>
            <b>
              <span>4.2. COLETA E USO DE DADOS E RESGISTRO DE ATIVIDADES</span>
            </b>
          </p>

          <p className="MsoNormal">
            <span>
              4.2.1. Os dados são coletados a partir da submissão voluntária
              pelo <b>USUÁRIO</b>, podendo ser enriquecidos posteriormente, e
              incluem:
            </span>
          </p>

          <p className="MsoNormal">
            <span></span>
          </p>

          <table
            className="MsoTableGrid"
            style={{ borderCollapse: "colapse", border: "none", margin: '0 auto' }}
          >
            <tbody>
            <tr>
              <td
                width={"187"}
                style={{
                  width: "140.0pt",
                  border: "solid #CCCCCC 1.0pt",
                  background: "whitesmoke",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  <b>
                    <span>TIPOS DE DADOS</span>
                  </b>
                </p>
              </td>
              <td
                width={"209px"}
                style={{
                  width: "156.8pt",
                  border: "solid #CCCCCC 1.0pt",
                  borderLeft: "none",
                  background: "whitesmoke",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal", color:'white' }}
                >
                  <b>
                    <span style={{ color: "black" }}>DADOS PESSOAIS</span>
                  </b>
                </p>
              </td>
              <td
                width={205}
                style={{
                  width: "153.95pt", backgroundColor: 'slategrey',
                  border: "solid #CCCCCC 1.0pt",
                  borderLeft: "none",
                  background: "whitesmoke",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal", color:'white' }}
                >
                  <b>
                    <span style={{ color: "black" }}>
                      FINALIDADE DE USO DOS DADOS
                    </span>
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                width={187}
                rowSpan={5}
                style={{
                  width: "140.0pt",
                  backgroundColor: 'slategrey',
                  borderTop: "none",
                  borderLeft: "solid #CCCCCC 1.0pt",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid #CCCCCC 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal", color:'white' }}
                >
                  <b>
                    <span>Cadastrais</span>
                  </b>
                </p>
              </td>
              <td
                width={209}
                rowSpan={5}
                style={{
                  width: "156.8pt",
                  borderTop: "none",
                  backgroundColor: 'slategrey',
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid #CCCCCC 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal", color:'white' }}
                >
                  <span>
                    Nome completo, E-mail, Número do telefone, Foto do rosto
                  </span>
                </p>
              </td>
              <td
                width={205}
                style={{
                  width: "153.95pt", backgroundColor: 'slategrey',
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid #CCCCCC 1.0pt",
                  borderRight: "solid #CCCCCC 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal", color:'white' }}
                >
                  <span>Identificar o Usuário</span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                width={205}
                style={{
                  width: "153.95pt", backgroundColor: 'slategrey',
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid #CCCCCC 1.0pt",
                  borderRight: "solid #CCCCCC 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal", color:'white' }}
                >
                  <span>Cumprimento de obrigação legal</span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                width={205}
                style={{
                  width: "153.95pt", backgroundColor: 'slategrey',
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid #CCCCCC 1.0pt",
                  borderRight: "solid #CCCCCC 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal", color:'white' }}
                >
                  <span>Administrar e enviar o QR Code personalizado.</span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                width={205}
                style={{
                  width: "153.95pt", backgroundColor: 'slategrey',
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid #CCCCCC 1.0pt",
                  borderRight: "solid #CCCCCC 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal", color:'white' }}
                >
                  <span>Promover serviços da PUZLI.</span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                width={205}
                style={{
                  width: "153.95pt", backgroundColor: 'slategrey',
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid #CCCCCC 1.0pt",
                  borderRight: "solid #CCCCCC 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal", color:'white' }}
                >
                  <span>
                    Informar sobre novidades, funcionalidades, conteúdos,
                    notícias e demais eventos relevantes para a manutenção do
                    relacionamento com os Usuários.{" "}
                  </span>
                </p>
              </td>
            </tr>
            </tbody>
          </table>

          <p className="MsoNormal" style={{ marginTop: "20px" }}>
            <span>
              4.2.2. A <b>PUZLI </b>não é responsável pela precisão, veracidade
              ou falta dela nas informações prestadas pelo <b>USUÁRIO</b>ou pela
              sua desatualização, sendo de responsabilidade do <b>USUÁRIO </b>
              prestá-las com exatidão ou atualizá-las.
            </span>
          </p>

          <p className="MsoNormal">
            <span>
              4.2.3. A <b>PUZLI </b>utiliza as tecnologias disponíveis sempre em
              respeito à Legislação aplicável e aos termos da presente{" "}
              <b>Política de Privacidade</b>.
            </span>
          </p>

          <p className="MsoNormal">
            <span>
              4.2.4. É importante destacar que, uma vez na <b>PUZLI</b>, o{" "}
              <b>USUÁRIO </b>poderá ser conduzido, via <i>link </i>a outros
              portais ou plataformas, que poderão coletar suas informações e ter
              sua própria Política de Privacidade.
            </span>
          </p>

          <p className="MsoNormal">
            <span>
              4.2.4.1. Cabe ao <b>USUÁRIO </b>ler a Política de Privacidade de
              tais plataformas fora do ambiente da <b>PUZLI</b>, sendo de
              responsabilidade do <b>USUÁRIO </b>aceitá-la ou rejeitá-la. A{" "}
              <b>PUZLI</b>não é responsável pela Política de Privacidade nem
              pelo conteúdo de quaisquer websites, conteúdo ou serviços ligados
              a <b>PUZLI</b>, inclusive por meio de links.{" "}
            </span>
          </p>

          <p className="MsoNormal">
            <span>
              4.2.5 O consentimento fornecido pelo <b>USUÁRIO</b>é coletado de
              forma individual, clara, específica e legítima.
            </span>
          </p>

          <p className="MsoNormal">
            <span>
              4.2.6 O <b>USUÁRIO </b>poderá alterar suas concessões de
              consentimento, conceder novas permissões ou retirar seu
              consentimento para as permissões atuais por meio dos{" "}
              <b>Canais de Atendimento da PUZLI</b>, sendo avisado das
              consequências que a retirada de consentimento poderá causar.
            </span>
          </p>

          <p className="MsoNormal">
            <span>
              4.2.7 Os dados coletados e as atividades registradas também
              poderão ser compartilhados:
            </span>
          </p>

          <p className="MsoListParagraphCxSpFirst style={{text-indent:-.25in">
            <span>
              a.
              <span style={{ font: "7.0pt 'Times New Roman'" }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            <span>
              Com autoridades judiciais, administrativas ou governamentais
              competentes, sempre que houver requerimento, requisição ou ordem
              judicial;
            </span>
          </p>

          <p className="MsoListParagraphCxSpMiddle style={{text-indent:-.25in">
            <span>
              b.
              <span style={{ font: "7.0pt 'Times New Roman'" }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span>
              De forma automática em caso de movimentações societárias, como
              fusão, aquisição e incorporação.
            </span>
          </p>

          <p className="MsoListParagraphCxSpLast style={{text-indent:-.25in">
            <span>
              c.
              <span style={{ font: "7.0pt 'Times New Roman'" }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            <span>
              Para promover os serviços da <b>PUZLI</b>, além de informar sobre
              novidades, funcionalidades, demais informações relevantes para
              funcionamento do sistema <b>PUZLI</b>.
            </span>
          </p>

          <p className="MsoNormal">
            <span>
              4.2.8 A base de dados formada por meio da coleta de dados na{" "}
              <b>PUZLI </b>é de propriedade e responsabilidade da <b>PUZLI</b>,
              sendo que seu uso, acesso e compartilhamento, quando necessários,
              serão feitos dentro dos limites e propósitos dos negócios da{" "}
              <b>PUZLI </b>e descritos nesta <b>Política de Privacidade</b>.
            </span>
          </p>

          <p className="MsoNormal">
            <span>
              4.2.8.1. O <b>ANFITRIÃO</b> é corresponsável pelo sigilo de seus
              dados pessoais. O compartilhamento de senhas e dados de acesso
              viola esta <b>Política de Privacidade</b> e os{" "}
              <b>Termos de Uso</b> da <b>PUZLI</b>.{" "}
            </span>
          </p>

          <p className="MsoNormal">
            <span>
              4.2.9 Internamente, os dados dos <b>USUÁRIOS</b>são acessados
              somente por profissionais devidamente autorizados pela{" "}
              <b>PUZLI</b>, respeitando os princípios de proporcionalidade,
              necessidade e relevância para os objetivos da <b>PUZLI</b>, além
              do compromisso de confidencialidade e preservação da privacidade
              nos termos desta <b>Política de Privacidade</b>.{" "}
            </span>
          </p>

          <p className="MsoNormal">
            <span>
              4.2.10 A PUZLI utiliza Cookies em seus websites, sendo que o{" "}
              <b>USUÁRIO </b>concorda com essa utilização ao aceitar essa
              Política de Privacidade. Os cookies podem ser utilizados para
              diversos propósitos, incluindo lembrar-se de você e de suas
              preferências, persistir informações relacionadas a suas atividades
              no site visitado, ou coletar informações que podem ser usadas para
              oferecer conteúdo de uma forma personalizada.
            </span>
          </p>

          <p className="MsoNormal" style={{ marginTop: "25px" }}>
            <b>
              <span>4.3. ARMAZENAMENTO DOS DADOS E REGISTROS</span>
            </b>
          </p>

          <p className="MsoNormal">
            <span>
              4.3.1 Os dados coletados e os registros de atividades estarão
              armazenados em ambiente seguro e controlado pelo prazo mínimo de 6
              (seis) meses, nos termos do Marco Civil da Internet, observado o
              estado da técnica disponível.{" "}
            </span>
          </p>

          <p className="MsoNormal">
            <span>
              4.3.1.1. Os dados poderão ser apagados antes desse prazo, caso
              haja solicitação do <b>USUÁRIO</b>. No entanto, pode ocorrer de os
              dados precisarem ser mantidos por período superior, por motivo de
              lei e/ou ordem judicial. Findo o qual, serão excluídos com uso de
              métodos de descarte seguro, ou utilizados de forma anonimizada
              para fins estatísticos.
            </span>
          </p>

          <p className="MsoNormal" style={{marginTop:'25px'}}>
            <b>
              <span>
                4.4. EXIBIÇÃO, RETIFICAÇÃO, LIMITAÇÃO, OPOSIÇÃO E EXCLUSÃO DE
                DADOS
              </span>
            </b>
          </p>

          <p className="MsoNormal">
            <span>
              4.4.1. O <b>USUÁRIO </b>pode solicitar a exibição ou retificação
              de seus dados pessoais, por meio dos <b>Canais de Atendimento</b>.
            </span>
          </p>

          <p className="MsoNormal">
            <span>
              4.4.2. Pelos <b>Canais de Atendimento</b>, o <b>USUÁRIO </b>poderá
              também requerer: (i) a limitação do uso de seus dados pessoais;
              (ii) manifestar sua oposição ao uso de seus dados pessoais ou
              (iii) solicitar a exclusão de seus dados pessoais coletados e
              registrados pela <b>PUZLI.</b>
            </span>
          </p>

          <p className="MsoNormal">
            <span>
              4.4.2.1. Caso o <b>USUÁRIO </b>retire seu consentimento para
              finalidades fundamentais ao regular funcionamento da <b>PUZLI</b>,
              os serviços e funcionalidades da <b>PUZLI</b>poderão ficar
              indisponíveis para esse <b>USUÁRIO, </b>tal como o funcionamento
              de seu QR Code na portaria do evento.
            </span>
          </p>

          <p className="MsoNormal">
            <span>
              4.4.2.2. As comunicações promocionais contêm ofertas do Sistema{" "}
              <b>PUZLI</b>, que podem ser enviadas aos <b>USUÁRIO</b>. A
              frequência do envio pode variar de acordo com a interação do{" "}
              <b>USUÁRIO </b>com os e-mails. O <b>USUÁRIO </b>no momento da
              aceitação concorda em receber essas informações de publicidade do
              Sistema <b>PUZLI</b>. Se você não deseja mais receber e-mails, (i)
              basta selecionar a opção &quot;cancelar o envio de
              comunicação&quot; no rodapé de cada e-mail para ser redirecionado
              para a página de confirmação de cancelamento.{" "}
            </span>
          </p>

          <p className="MsoNormal">
            <span>
              4.4.3. Para fins de auditoria, segurança, controle de fraudes e
              preservação de direitos, a <b>PUZLI </b>poderá permanecer com o
              histórico de registro dos dados dos <b>USUÁRIOS </b>por prazo
              maior nas hipóteses que a lei ou norma regulatória assim
              estabelecer ou para preservação de direitos.
            </span>
          </p>

          <p className="MsoNormal" style={{marginTop:'25px'}}>
            <span></span>
            <b>
              <span>4.5. DISPOSIÇÕES GERAIS</span>
            </b>
          </p>

          <p className="MsoNormal">
            <span>
              4.5.1. A <b>PUZLI </b>reserva-se o direito de alterar o teor desta{" "}
              <b>Política de Privacidade</b> a qualquer momento, conforme a
              finalidade ou necessidade para adequação e conformidade legal.
              Ocorrendo atualizações neste documento e que demandem nova coleta
              de consentimento, a <b>PUZLI</b>
              notificará o <b>USUÁRIO </b>pelos meios de contato por ele
              fornecidos.
            </span>
          </p>

          <p className="MsoNormal">
            <span>
              4.5.2. Em caso de qualquer dúvida com relação às disposições
              constantes desta <b>Política de Privacidade</b>, o <b>USUÁRIO </b>
              poderá entrar em contato com a <b>PUZLI </b>por meio do e-mail
              puzli@puzli.com.br.
            </span>
          </p>

          <p className="MsoNormal">
            <span>
              4.5.3    Ao realizar o seu cadastramento e aceitar esse
              Regulamento, o Participante também concordará com os termos da
              Políca de Privacidade disponível no <b>sistema Puzli</b>.
            </span>
          </p>

          <p className="MsoNormal" style={{marginTop:'25px'}}>
            <b>
              <span>
                5.    DISPOSIÇÕES FINAIS
              </span>
            </b>
          </p>

          <p className="MsoNormal">
            <span>
              5.1.    Dúvidas, críticas ou sugestões, poderão ser encaminhadas
              para os canais de atendimento disponíveis ou diretamente por meio
              do <b>sistema Puzli</b>. As ocorrências encaminhadas serão
              respondidas por ordem cronológica, respeitando os limites impostos
              pelo Código de Defesa do Consumidor e demais normas consumeristas.{" "}
            </span>
            <span style={{ fontSize: "14pt" }}> </span>
          </p>

          <p className="MsoNormal" style={{ textAlign: "justify" }}>
            <span>
              5.2.    Se qualquer cláusula ou trecho de cláusula deste
              Regulamento for considerada inválida ou inexequível, tal cláusula
              ou trecho deverá ser interpretado de forma restritiva, de acordo
              com a lei aplicável, para refletir, na medida do possível, a
              intenção original do espírito do <b>sistema Puzli</b>, sendo certo
              que as demais disposições permanecerão em vigor e aptas para
              produzirem efeitos.{" "}
            </span>
          </p>

          <p className="MsoNormal" style={{ textAlign: "justify" }}>
            <span>
              5.3.    Qualquer tolerância das partes com relação ao
              descumprimento de alguma previsão deste Regulamento não implicará
              novação ou renúncia de direitos, de modo que a parte tolerante
              poderá, a qualquer momento, exigir da outra o fiel cumprimento das
              disposições do presente.{" "}
            </span>
          </p>

          <p className="MsoNormal" style={{ textAlign: "justify" }}>
            <span></span>
          </p>

          <p className="MsoNormal" style={{ textAlign: "justify" }}>
            <span>
              5.4.    Fica eleito o foro Central da Comarca da capital do Estado
              do Ceará como o único competente para dirimir toda e qualquer
              dúvida advinda deste regulamento.
            </span>
          </p>

          <p className="MsoNormal">
            <span style={{ fontSize: "14pt" }}>&nbsp;</span>
          </p>

          <p className="MsoNormal">
            <b>
              <span>Informações da encarregada de dados:</span>
            </b>
          </p>

          <p className="MsoNormal">
            <span>Denise Rocha</span>
          </p>

          <p className="MsoNormal">
            <span>
              Para fazer uma solicitação ao Encarregado de Dados, use o email:
              puzli@puzli.com.br
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default Regulamento;
